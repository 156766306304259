<template>
  <docs-layout> 
    <div class="space-y-4 w-full">
      <h1>Variants</h1>
      <asom-alert v-for="(v, i) in variants" :key="i" :variant="v" :title="`Alert of variant ${v}`">
      </asom-alert>
      <h1>Dismissible</h1>
      <asom-alert dismissible title="Dismissible alert">
      </asom-alert>
      <h1>Content</h1>
      <p>Closely follow examples at https://tailwindui.com/components/application-ui/feedback/alerts for display content of alert.</p>
      <p>An alert normally display with list, so please apply correct list class and styling</p>
      <asom-alert title="There were 2 errors with your submission" variant="error">
        <div class="mt-2 text-sm text-red-700">
          <ul class="list-disc pl-5 space-y-1">
            <li>
              Your password must be at least 8 characters
            </li>
            <li>
              Your password must include at least one pro wrestling finishing move
            </li>
          </ul>
        </div>
      </asom-alert>
      <p>We can use an object/string to pass to error content via prop error-message. As for object, backend has standardized the error response format so we will use it as base for formatting</p>
      <asom-alert variant="error" :error-message="errorMessageSample" />
    </div>
  </docs-layout>
</template>
<script>
import DocsLayout from '@/docs/DocsLayout.vue'
export default {
  components: {
    DocsLayout,
  },
  data() {
    return {
      variants: [ 'info', 'success', 'warning', 'error' ],
      errorMessageSample: {
        success: false,
        message: {
          'err1': "Error 1: Lorem ipsum dolor sit amet, consectetur adipiscing elit ",
          'err2': "Error 2: Lorem ipsum dolor sit amet, consectetur adipiscing elit",
          'err3': "Error 3: Lorem ipsum dolor sit amet, consectetur adipiscing elit",
          'err4': "Error 4: Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        }
      }
    }
  }
}
</script>